import { Box, Icon, Typography } from "@mui/material";
import { ClientOnly } from "remix-utils/client-only";
import type { MouseEvent } from "react";
import type { ProductJson } from "~/jsonSchemas";
import type { Product } from "~/schemas";
import { useCartStore } from "~/store";

interface CartCounterProps {
  variationId: string;
  product: Product | ProductJson;
}

export default function CartCounter({
  variationId,
  product,
}: CartCounterProps) {
  const [pQty, increment, decrement] = useCartStore((s) => [
    s.cart[product.id],
    s.incrementCartQty,
    s.decrementCartQty,
  ]);
  const qty = pQty ? pQty[variationId] : undefined;

  const incrementCounter = (e: MouseEvent<HTMLButtonElement>) => {
    // Prevent the card from navigating.
    e.stopPropagation();
    e.preventDefault();

    increment(product, variationId);
  };

  const decrementCounter = (e: MouseEvent<HTMLButtonElement>) => {
    // Prevent the card from navigating.
    e.stopPropagation();
    e.preventDefault();

    decrement(product, variationId);
  };

  return (
    <ClientOnly fallback={<div style={{ height: "40px" }} />}>
      {() => (
        <div style={{ display: "flex" }}>
          {/* The parent div is necessary to limit the counter's width to the minimum required. */}
          <Box
            sx={{
              border: "1px solid #333",
              borderRadius: "8px",
              borderColor: "#333",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Icon
              sx={{
                fontSize: "0.5em",
                cursor: "pointer",
                width: "unset",
                height: "100%",
                p: (t) => t.spacing(2),
              }}
              color={qty === 1 ? "disabled" : undefined}
              onClick={decrementCounter}
            >
              remove
            </Icon>
            <Typography variant="body1">{qty ?? 1}</Typography>
            <Icon
              sx={{
                fontSize: "0.75em",
                cursor: "pointer",
                width: "unset",
                height: "100%",
                p: (t) => t.spacing(2),
              }}
              onClick={incrementCounter}
            >
              add
            </Icon>
          </Box>
        </div>
      )}
    </ClientOnly>
  );
}
